.banner {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 100px;
    overflow: hidden;
    transition: 0.5s;
}

.banner .playButtonWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
}

.banner::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.banner .service {
    padding: 0 100px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding-bottom: 100px;
}

.banner .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-size: 8rem;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
}

.bgImg.active {
    visibility: visible;
    opacity: 1;
}

/* Media Queries */
@media (max-width: 768px) {
    .banner {
        min-height: 70vh;
    }

    .banner .service {
        padding: 0 50px;
    }

    .banner .bgImg {
        position: absolute;
        top: 0;
        left: 0;
        height: 70%;
    }

    .banner .playButtonWrapper {
        top: 40vh;
    }
}