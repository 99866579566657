@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --primary-color: #ff3700;
  --secondary-color: #08c0dd;
  --basic--color: #249eff;
  --pro-color: #ff1f6b;
  --vip-color: #98ff21;
}

body {
  background: #000000;
  font-family: 'Roboto', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}