.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    background: var(--primary-color);
    color: #ffffff;
    width: 40px;
    height: 40px;
    text-decoration: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1100;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 1.5rem;
    line-height: 0;
}

.back-to-top:hover {
    background: #000000;
    color: var(--primary-color);
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}