.about__figure img {
    width: 540px;
    height: 100%;
}

.about__figure > .about__image {
    padding-inline-start: 50px;
}

.about .about__figure-image {
    position: absolute;
}

.about .about__figure-image::before {
    z-index: -1;
}

.about .about__figure-image-top {
    bottom: -120px;
    /* left: 0; */
    width: 135px;
    padding-block: 50px;
}

.about .about__figure-image-bottom {
    top: -65px;
    right: 0;
    overflow: hidden;
}

.about .about__figure-image-top::before {
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
    /* background-image: ; */
}
