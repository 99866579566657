.navBar li {
    list-style: none;
    margin: 0 20px;
}

.navBar li a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}

.navBar li a:hover,
.navBar li a.active {
    color: var(--primary);
}


.navBar li a:hover,
.navBar li a.active {
    color: var(--primary-color);
}

.navBarMenuLink {
    color: #ffffff;
    font-weight: 450;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}

.navBarMenuLink:hover,
.navBarMenuLink.active,
.navBarMobileMenuLink:hover {
    color: var(--primary-color);
}