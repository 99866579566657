.swiper {
    padding-top: 36px;
    padding-bottom: 36px;
}

.serviceSwiper {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 63vw;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 250px;
    height: 250px;
}

.swiper-slide img {
    display: block;
    height: 100%;
}

/* Media Queries */
@media (max-width: 768px) {
    .swiper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .serviceSwiper {
        width: 100vw;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 180px;
        height: 180px;
    }
}