.mainBtn {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
    background: var(--primary-color);
    color: #ffffff;
    padding: 5px 16px;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.3s;
    cursor:  pointer !important;
    border: none;
}

.mainBtn:hover {
    color: var(--primary-color) !important;
    background: #ffffff !important;
    cursor:  pointer !important;
}