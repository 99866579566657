.services__card {
    --color: #ff88a6;
    --color-reguler: #22F211;
    --color-vip: #3B66F7;
    --color-karaoke: #FF88A6;
    --border-size: 1rem;
    --bg-color: #252525;
    position: relative;
    background-color: var(--bg-color);
    /* width: 280px; */
    /* margin: 2rem; */
    border-radius: var(--border-size);
    color: #999999;
    display: grid;
    place-content: center;
    padding: 3rem 2rem;
    isolation: isolate;
    overflow: hidden;
    cursor: pointer;
}

/* .services__card::before {
    content: '';
    position: absolute;
    width: 90%;
    height: 180%;
    background: #ff0800;
    transform: rotate(45deg);
}

.services__card:hover::before {
    animation: animate 2s linear infinite;
}

@keyframes animate {
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
        filter: hue-rotate(-360deg) saturate(1000%);
    }
}

.services__card::after{
    content: '';
    position: absolute;
    inset: 10px;
    background-color: #000;
    border-radius: 8px;
}

.services__card-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-block: 2.5rem 1.5rem;
    color: #fff;
} */

.services__card::before, 
.services__card::after {
    content: '';
    position: absolute;
}

.card-regular::before,
.card-vip::before,
.card-karaoke::before {
    width: 200%;
    height: 200%;
    inset: -50%;
    z-index: -2;
    animation: border 9s linear infinite;
}

.card-regular::before {
    background-image: conic-gradient(var(--color-reguler) 0deg, transparent 60deg, transparent 180deg, var(--color-reguler) 180deg, transparent 240deg);
}

.card-vip::before {
    background-image: conic-gradient(var(--color-vip) 0deg, transparent 60deg, transparent 180deg, var(--color-vip) 180deg, transparent 240deg);
}

.card-karaoke::before {
    background-image: conic-gradient(var(--color-karaoke) 0deg, transparent 60deg, transparent 180deg, var(--color-karaoke) 180deg, transparent 240deg);
}

.services__card:hover::before {
    animation-play-state: paused;
}

@keyframes border {
    to {
        transform: rotateZ(-360deg);
    }
}

.services__card::after {
    --inset: 0.25rem;
    background-color: #111;
    inset: var(--inset);
    border-radius: calc(
        var(--border-size) - var(--inset)
    );
    z-index: -1;
    transition: all 0.45s linear;
}

.services__card:hover::after {
    background-color: var(--bg-color);
}

.services__card-icon {
    font-size: 4rem;
    margin-inline: auto;
    margin: 0 auto;
    color: #acacac;
    /* -webkit-text-stroke-width: thin var(--color); */
    -webkit-text-stroke: 1px var(--color)
}

.services__card:hover i {
    color: var(--color);
    /* -webkit-text-stroke-width: 0; */
    -webkit-text-stroke: none;
}

.services__card__title {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.875rem;
    /* margin-block: 2.5rem 1.5rem; */
    margin: 2.5rem 1.5rem 0.5rem;
    color: #999999;
}

.services__card__info {
    align-items: center;
    padding-bottom: 2.5rem;
    font-size: 1.35rem;
}

.services__card__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* text-align: left; */
    /* gap: 1.5rem; */
}

.services__card__item > * {
    margin: 0 0.5rem;
}

.services__card__item-text-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #999999;
}

.services__card__item-price {
    font-size: 1.35rem;
}

/* Media Queries */
@media (max-width: 768px) {
    .services__card__info {
        font-size: 1rem;
    }    
}