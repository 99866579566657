/* main {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 0 120px;
    background: #000000;
}

main::before {
    content: '';
    position: absolute;
    left: 0;
    top: -80px;
    width: 100%;
    height: 200px;
    background: #000000;
    filter: blur(50px);
} */

section {
    position: relative;
    overflow: hidden;
    padding: 45px 90px 20px;
    width: 100%;
    transition: ease-in-out 1s;
    transform-origin: bottom;
    z-index: 90;
}

section .section-title {
    position: relative;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

section .section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -30px;
    height: 5px;
    width: 50%;
    background: linear-gradient(90deg, var(--primary-color), var(--primary-color), transparent);
    z-index: 1000;
}

section p {
    font-weight: 300;
    color: #111111;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

/* Media Queries */
@media (max-width: 768px) {
    section {
        padding: 30px 20px 20px;
    }
}