.banner .trailer {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 900;
}

.banner .trailer.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.trailer p {
    color: var(--primary);
    font-size: 1.5rem;
    margin-left: 20px;
    margin-bottom: 0;
}

/* .banner .playBtn {
    position: relative;
    color: #ffffff;
    width: 80px;
    height: 80px;
    transition: 1s;
    z-index: 1000;
    background: transparent;
    backdrop-filter: blur(20px);
    text-decoration: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
} */

.banner .playBtn-icon {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1100;
    display: block;
    box-sizing: content-box;
    width: 2rem;
    height: 2.75rem;
    border-radius: 50%;
    cursor: pointer;
    padding: 1.25rem 1.25rem 1.25rem 1.75rem;
}

/* Play Button Circle */
.banner .playBtn-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1000;
    background: #00c9db;
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    display: block;
    transition: all 200ms;
}

/* Play Button Triangle */
.banner .playBtn-icon span {
    position: relative;
    display: block;
    z-index: 1100;
    top: 0.36rem;
    left: 0.27rem;
    width: 0;
    height: 0;
    border-left: 1.63rem solid #ffffff;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
}

/* Play Button Animation */
.banner .playBtn-icon::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1000;
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    background: #00c9db;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) scale(1.5);
        opacity: 0;
    }
}

/* .banner .playBtn i {
    font-size: 4rem;
    color: var(--primary);
    cursor: pointer;
} */

.banner .playBtn::before {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    content: '';
    background: transparent;
    border: 10px solid #ffffff;
    border-radius: 50%;
    animation: button 1s linear infinite;
}

@keyframes button {
    0%,
    100% {
        opacity: 0.3;
        filter: blur(4px);
    }
    50% {
        opacity: 1;
        filter: blur(10px);
    }
}

/* Media Queries */
@media (max-width: 768px) {
    
}