.serviceModalWrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); /* Safari support */
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
    z-index: 1500;
    /* opacity: 0;
    visibility: hidden;
    transition: 1s; */
}

.serviceModalContent {
    /* content: ''; */
    position: relative;
    max-width: 900px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 10% auto;
    /* top: 125px; */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 9px;
}

.serviceModalWrapper iframe {
    max-width: 100%;
    max-height: 100%;
}

.serviceModalWrapper .modalClose {
    position: absolute;
    top: 10px;
    right: 30px;
    padding: 9px;
    color: #ffffff;
    font-size: 45px;
    cursor: pointer;
}

.serviceModalWrapper.active {
    /* opacity: 1;
    visibility: visible; */
    display: flex;
}

/* Media Queries */
@media (max-width: 768px) {
    .serviceModalWrapper {
        top: 20vh;
        height: 50%;
    }

    .serviceModalContent {
        margin: 5% auto;
        padding: 10px;
    }

    .serviceModalWrapper .modalClose {
        top: 10px;
        right: 20px;
        padding: 9px;
        font-size: 36px;
    }
}