.contact {
    justify-content: center;
    align-items: center;
}

.contact .filters {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 10px;
    margin-top: 50px;
}

.contact .filters li {
    color: #ffffff;
    padding: 10px 25px;
    background: #191919;
    transition: 0.3s;
    text-transform: uppercase;
    cursor: pointer;
}

.contact .filters li:hover,
.contact .filters li.active {
    background: var(--primary-color);
}