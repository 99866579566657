.map {
    position: relative;
    overflow: hidden;
    height: 0;
    margin-bottom: 1rem;
    padding-bottom: 50%;
    border-radius: 0.25rem;
}

.map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}